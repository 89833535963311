const m = require('mithril')
const data = require('../lib/data')

module.exports = {
  _loadCount (model, query) {
    return data.dataQueryCount({ model, query })
      .then((res) => {
        this.count = res.data.dataQueryCount
        m.redraw()
      })
  },
  onremove () {
  },
  oninit (vnode) {
    this.count = '...'
    this._loadCount(vnode.attrs.model, vnode.attrs.query)
  },
  view (vnode) {
    return m('span', {}, this.count)
  }
}
