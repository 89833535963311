const m = require('mithril')
const yaml = require('js-yaml')

const control = require('../lib/control')
const snippetsLib = require('../lib/snippets')

let result = null
let loading = false
let source = null
let sourceProcessed = null
let options = {}

function execute () {
  result = null
  loading = true
  control.snippetRun({ source, input: options }).then((res) => {
    result = res
    loading = false
  })
  return false
}

function updateOpt (key) {
  return function (t) {
    options[key] = t.target.value
  }
}

function fillSample () {
  if (!sourceProcessed) {
    return null
  }
  for (const k of Object.keys(sourceProcessed.schema.input)) {
    const v = sourceProcessed.schema.input[k]
    if (v.example) {
      options[k] = v.example
    }
  }
}

module.exports = {

  oninit () {
    result = null
    loading = false
    source = null
    sourceProcessed = null
    options = {}
  },

  view (vnode) {
    source = vnode.attrs.source

    if (!source) {
      return m('div', 'No source')
    }

    const s = sourceProcessed = snippetsLib.process(source)

    return m('div', [
      m('form', [
        Object.keys(s.schema.input).map(ik => {
          const input = s.schema.input[ik]
          const required = s.schema.inputRequired || []
          let field = null
	        if (input?.dependsOn) {
            const dependants = Object.keys(input.dependsOn)
            for (const d of dependants) {
              if (!options[d] || options[d] !== input.dependsOn[d]) return null
            }
          }
          if (input.type === 'string') {
            if (input.enum) {
              field = m('div.select', m('select', { onchange: updateOpt(ik), value: options[ik] }, [null].concat(input.enum).map(k => {
                return m('option', { value: k }, k === null ? '(choose)' : k)
              })))
            } else {
              field = m('input.input', { onchange: updateOpt(ik), type: 'text', value: options[ik] })
            }
          }
          return m('.field', [
            m('label.label', (input.title || ik) + (required.indexOf(ik) !== -1 ? ' (required)' : '')),
            m('.control', field)
          ])
        }),
        m('.field', [
          m('.control', [
            m('button.button.is-link', { onclick: execute, disabled: loading }, 'Execute')
          ])
        ]),
        m('a', { onclick: fillSample }, 'Fill sample data')
      ]),
      !loading ? '' : m('div', 'Loading ..'),
	    !result
		    ? ''
		    : m('div', { style: 'margin-top: 2em;' }, [
			    m('.title.is-5', 'Result'),
			    result.errors
				    ? m('.notification.is-danger', result.errors[0].message)
				    : m('pre.code', yaml.dump(result.data.snippetRun.output))
		    ])
    ])
  }
}
