const m = require('mithril')
const data = require('../lib/data')

module.exports = {
  _loadItem (col, id, colType, vnode) {
    return data.dataQuery({ model: col.model, query: { _id: { $eq: id } } })
      .then((res) => {
        this.item = res.data.dataQuery[0]
        m.redraw()
      })
  },
  onremove () {
  },
  oninit (vnode) {
    this.item = null
    if (!vnode.attrs.cols) {
      return null
    }
    if (vnode.attrs.resolve) {
      this.col = vnode.attrs.cols[vnode.attrs.linkType]
      this.id = vnode.attrs.linkId
      this._loadItem(this.col, this.id, vnode.attrs.linkType)
    }
  },
  view (vnode) {
    const href = `/db/${vnode.attrs.linkType}/${vnode.attrs.linkId}`
    const text = vnode.attrs.linkView ? vnode.attrs.linkView(href) : vnode.attrs.linkId
    let suffix = null
    const col = vnode.attrs.cols ? vnode.attrs.cols[vnode.attrs.linkType] : null
    if (this.item && col && col.preview) {
      suffix = '<br/>' + col.preview(this.item)
    }
    return m('span', [
      m(m.route.Link, { href }, text),
      m('small', { style: { color: 'gray' } }, m.trust(suffix))
    ])
  }
}
