const m = require('mithril')
const yaml = require('js-yaml')
const data = require('../lib/data')
const Colored = require('./colored')
const control = require('../lib/control')

const pageStore = {}

function loadPageStore () {
  data.cacheInfo().then(res => {
    pageStore.cacheInfo = res.data.cacheInfo
    console.log(pageStore)
    m.redraw()
  })
}

function cacheFlush () {
  pageStore.cacheInfo = null
  control.cacheFlush().then(res => {
    loadPageStore()
  })
}

function cacheReload () {
  loadPageStore()
}

module.exports = {

  oninit () {
    loadPageStore()
  },

  view () {
    return m('div', [
      m('.title.is-3', 'Cache'),
      pageStore.cacheInfo ? m('.content', [
        // m('div', 'Total items: ' + pageStore.cacheInfo.items),
        m('.content', [
          m('button.button.is-danger', { onclick: cacheFlush }, 'Flush cache')
        ]),
        m('.title.is-4', 'Cache stats'),
        m('.content', [
          m('button.button', { onclick: cacheReload }, 'Reload')
        ]),
        m('pre.code', m(Colored, { text: yaml.safeDump(pageStore.cacheInfo.memStats) })),
        m('.title.is-4', 'Server info'),
        m('pre.code', m(Colored, { text: yaml.safeDump(pageStore.cacheInfo.serverInfo) }))
      ]) : m('div', 'Loading cache status ..')
    ])
  }
}
