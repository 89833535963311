const m = require('mithril')
const uson = require('uson')
const yaml = require('js-yaml')
const Colored = require('./colored')

let usonText = 'string:str!42 number:12.05 text:Banana quotedText:"John Devilseed" empty:null good:true s1:s2:{s3a:s4:s5:done s3b:{}}'
let usonOutput = null
let usonFormat = 'yaml'

function usonSubmit (vnode) {
  return (e) => {
    usonOutput = uson.parse(usonText)
    return false
  }
}
function usonTextUpdate (vnode) {
  return (e) => {
    usonText = e.target.value
  }
}
function usonFormatUpdate (vnode) {
  return (e) => {
    usonFormat = e.target.value
  }
}

function usonRenderOutput (vnode, output) {
  if (usonFormat === 'yaml') {
    return m(Colored, { text: yaml.safeDump(output) })
  }
  return m(Colored, { text: JSON.stringify(output, null, 2), type: 'json' })
}

module.exports = {
  view (vnode) {
    return m('div', [
      m('.title.is-3', 'Tools'),
      m('.title.is-4', 'μson playground'),
      m('.notification', [
        'More info about μson (uson) you can found here: ',
        m('a', { href: 'https://github.com/burningtree/uson#%CE%BCson-overview' }, 'μson Overview'),
        ' (external link)'
      ]),
      m('form.form', { onsubmit: usonSubmit(vnode) }, [
        m('textarea.textarea', { oninput: usonTextUpdate(vnode), value: usonText }),
        m('.level', { style: 'margin-top: 1em; margin-bottom: 2em;' }, [
          m('.level-left', [
            m('.level-item', [
              m('button.button.is-primary', { value: 'yaml', onclick: usonFormatUpdate(vnode) }, 'USON → YAML')
            ]),
            m('.level-item', [
              m('button.button', { value: 'json', onclick: usonFormatUpdate(vnode) }, 'USON → JSON')
            ])
          ])
        ])
      ]),
      usonOutput ? usonRenderOutput(vnode, usonOutput) : ''
    ])
  }
}
