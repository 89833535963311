const m = require('mithril')
const snippetsLib = require('../lib/snippets')
const control = require('../lib/control')

let snippets = []
let snippetName = ''

function reload () {
  return function () {
    snippets = []
    snippetsLib.load().then(arr => {
      snippets = arr
    })
  }
}

function updateSnippetName (t) {
  snippetName = t.target.value
}

function createSnippet () {
  if (!snippetName) {
    return null
  }
  control.objectCreate({
    model: 'snippet',
    id: snippetName,
    data: {}
  }).then((res) => {
    m.route.set(`/snippet/${snippetName}@env`)
  })

  return false
}

module.exports = {

  oninit (vnode) {
    snippets = []
    snippetsLib.load().then(arr => {
      snippets = arr
    })
  },
  view (vnode) {
    return m('div', [
      m('.level', [
        m('.level-left', [
          m('.title.is-3.level-item', [m('i', { class: 'fas fa-play' }), 'Snippets'])
        ]),
        m('.level-right', [
          m('.level-item', [
            m('button.button', { onclick: reload(vnode) }, 'Reload snippets')
          ]),
          m('.level-item', [
            m(m.route.Link, { href: '/snippet-debug' }, m('button.button', 'Debug'))
          ])
        ])
      ]),
      m('table.table.is-fullwidth', [
        m('thead', [
          m('tr', [
            m('th', 'ID'),
            m('th', 'Name'),
            m('th', 'Min. BE version'),
            m('th')
          ])
        ]),
        m('tbody', snippets.map(s => {
          return m('tr', [
            m('td', [
              m(m.route.Link, { href: `/snippet/${s.id}` }, s.id),
              (s.local ? ' [db]' : '')
            ]),
            m('td', s.name),
            m('td', s.minVersion),
            m('td', [
            ])
          ])
        }))
      ]),
      m('.title.is-4', { style: 'margin-top: 2em;' }, 'Add snippet'),
      m('form', [
        m('.field', [
          m('label.label', 'Snippet ID'),
          m('.control', [
            m('input.input', { oninput: updateSnippetName, type: 'text', value: snippetName })
          ])
        ]),
        m('.field', [
          m('.control', [
            m('button.button.is-link', { onclick: createSnippet }, 'Create snippet')
          ])
        ])
      ])
    ])
  }
}
