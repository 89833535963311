const m = require('mithril')

class DS {
  constructor () {
    console.log('ds lib initialized')
    this.token = null
    this.url = 'http://localhost:3002/'
  }

  query (query, variables = {}) {
    // console.log(`GraphQL query: ${query}`)
    const req = {
      url: this.url,
      method: 'POST',
      headers: {},
      body: {
        query,
        variables
      }
    }
    if (this.token) {
      req.headers.authorization = this.token ? 'Bearer ' + this.token : null
    }
    return m.request(req)
  }
}

class DSWrapper {
  constructor () {
    if (!DSWrapper.instance) {
      DSWrapper.instance = new DS()
    }
  }

  getInstance () {
    return DSWrapper.instance
  }
}

module.exports = (() => (new DSWrapper()).getInstance())()
