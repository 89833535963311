module.exports = {
  contractTypes: {
    name: 'Contract Types'
  },
  rules: {
    name: 'Rules'
  },
  notifications: {
    name: 'Notifications'
  },
  variables: {
    name: 'Variables'
  },
  timezones: {
    name: 'Timezones'
  },
  locales: {
    name: 'Locales (i18n)'
  },
  graphql: {
    name: 'GraphQL schema',
    syntax: 'graphql'
  },
  graphqlAdmin: {
    name: 'GraphQL Admin schema',
    syntax: 'graphql'
  }
}
