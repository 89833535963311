const ds = require('./ds')

module.exports = {
  meta () {
    return ds.query('query { meta { version commit platform node arch uptime } }')
  },
  cacheInfo () {
    return ds.query('query { cacheInfo { items memStats driver { name version url } serverInfo } }')
  },
  socketInfo () {
    return ds.query('query { socketInfo { type servers group numSubscriptions driver { name version url } } }')
  },
  dbCollections (opts) {
    return ds.query('query ($cols: [JSON!]!) { dbCollections(cols: $cols) }', opts)
  },
  dataQuery (opts = {}, count = false) {
    if (!opts.sort) {
      opts.sort = { created: -1 }
    }
    if (!opts.query) {
      opts.query = {}
    }
    if (!opts.limit) {
      opts.limit = 1000
    }
    return ds.query(`query ($model: String!, $sort: JSON, $query: JSON, $limit: Int, $offset: Int, $hideArchived: Boolean) {
      dataQuery(model: $model, query: $query, limit: $limit, sort: $sort, offset: $offset, hideArchived: $hideArchived)
      ${count ? 'dataQueryCount(model: $model, query: $query)' : ''}
    }`, opts)
  },
  dataQueryCount (opts = {}) {
    if (!opts.query) {
      opts.query = {}
    }
    return ds.query(`query ($model: String!, $query: JSON) {
      dataQueryCount(model: $model, query: $query)
    }`, opts)
  },
  config () {
    return ds.query('query { config }')
  },
  spec (opts) {
    return ds.query('query ($type: String!) { spec(type: $type) }', opts)
  },
  messagePreview (opts = {}) {
    return ds.query('query ($type: String!, $language: String) { messagePreview(type: $type, language: $language) }', opts)
  }
}
