const m = require('mithril')

let snippets = null

module.exports = {
  instances: ({ env }) => {
    return m.request('https://master.dayswaps.top/instances').then(res => {
      return res.filter(x => x.env === env)
    })
  },
  snippets: (reload = false) => {
    if (!reload && snippets) {
      return Promise.resolve(snippets)
    }
    return m.request('https://master.dayswaps.top/snippets').then(res => {
      snippets = res
      return snippets
    })
  }
}
