const m = require('mithril')
const yaml = require('js-yaml')
const jmespath = require('jmespath')
const Colored = require('./colored')

let filter = ''

function filterUpdate (e) {
  filter = e.target.value
}

function renderConfig (config) {
  return yaml.safeDump(filter ? jmespath.search(config, filter) : config)
}

module.exports = {
  onremove () {
    filter = ''
  },
  view (vnode) {
    return m('div', [
      m('.title.is-3', 'Config'),
      m('.title.is-4', 'engine'),
      m('form.form', [
        m('input.input', { placeholder: 'Filter ..', oninput: filterUpdate })
      ]),
      vnode.attrs.config ? m(Colored, { text: renderConfig(vnode.attrs.config) }) : m('div', 'Loading config ..')
    ])
  }
}
