const m = require('mithril')
const hljs = require('highlight.js')

hljs.registerLanguage('graphql', function (e) {
  return {
    aliases: ['gql'],
    keywords: {
      keyword: 'query mutation subscription|10 type input schema directive interface union scalar fragment|10 enum on ...',
      literal: 'true false null'
    },
    contains: [
      e.HASH_COMMENT_MODE,
      e.QUOTE_STRING_MODE,
      e.NUMBER_MODE,
      {
        className: 'type',
        begin: '[^\\w][A-Z][a-z]',
        end: '\\W',
        excludeEnd: !0
      },
      {
        className: 'literal',
        begin: '[^\\w][A-Z][A-Z]',
        end: '\\W',
        excludeEnd: !0
      },
      {
        className: 'variable',
        begin: '\\$',
        end: '\\W',
        excludeEnd: !0
      },
      {
        className: 'keyword',
        begin: '[.]{2}',
        end: '\\.'
      },
      {
        className: 'meta',
        begin: '@',
        end: '\\W',
        excludeEnd: !0
      }
    ],
    illegal: /([;<']|BEGIN)/
  }
})

module.exports = {
  view (vnode) {
    if (!vnode.attrs.text) {
      return m('div', '(null)')
    }
    const hl = hljs.highlight(vnode.attrs.type || 'yaml', vnode.attrs.text)
    return m('div', m('pre', m('code', m.trust(hl.value))))
  }
}
