const m = require('mithril')
const runComponent = require('./snippetrun')
const snippetsLib = require('../lib/snippets')
const control = require('../lib/control')

const example = `module.exports = {
  name: 'NAME',
  minVersion: '2.4.13',
  schema: \`
    input:
      prop1:
        type: string
    inputRequired:
      - prop1
  \`,
  run (opts) {
    return \`prop=>\${opts.prop1}\`
  }
}`

let orig = null
let item = null
let saving = false
let changed = false
let source = ''

function updateSource (t) {
  source = t.target.value
  changed = !(orig === source)
}

function fillExample () {
  source = example
}

function saveSnippet () {
  if (!item) {
    return null
  }
  saving = true
  control.objectUpdate({
    model: 'snippet',
    id: item.id.split('@')[0],
    data: {
      source,
      dec: snippetsLib.process(source)
    }
  }).then((res) => {
    saving = false
    changed = false
    orig = source
  })
}

module.exports = {

  oninit (vnode) {
    item = null
    saving = false
    changed = false
    orig = null
    source = ''
    if (vnode.attrs.id) {
      snippetsLib.load().then(arr => {
        item = arr.find(s => s.id === vnode.attrs.id)
        console.log(item)
        source = item.source
        orig = source
      })
    }
  },

  view () {
    return m('div', [
      m('.level', [
        m('.level-left', [
          m('.title.is-3.level-item', [m('i', { class: 'fas fa-play' }), 'Snippet Debug'])
        ])
      ]),
      !item
        ? ''
        : m('div', { style: 'margin-bottom: 2em' }, [
          m('div', [
            'Snippet: ',
            m(m.route.Link, { href: `/snippet/${item.id}` }, item.id)
          ])
        ]),
      m('div', { style: 'margin-bottom: 2em' }, [
        m('.title.is-4', 'Source'),
        m('textarea.textarea', { onchange: updateSource, oninput: updateSource, style: 'font-family: monospace; font-size: 14px; height: 30em;', value: source }),
        m('.level', [
          m('.level-left', [
            m('.level-item', [
              item && item.local ? m('button.button', { class: changed ? 'is-link' : '', onclick: saveSnippet, style: 'margin-top: 1em;', disabled: saving }, 'Save') : ''
            ]),
            source ? '' : m('a.level-item', { onclick: fillExample }, 'Fill with example')
          ])
        ])
      ]),
      m('div', { style: 'margin-bottom: 2em' }, [
        m('.title.is-4', 'Run'),
        m(runComponent, { source })
      ]),
      m('div', { style: 'margin-bottom: 10em;' })
    ])
  }
}
