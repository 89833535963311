const m = require('mithril')
const data = require('../lib/data')

module.exports = {
  _loadValue (model, query, key) {
    return data.dataQuery({ model, query, limit: 1 })
      .then((res) => {
	              this.value = res.data.dataQuery[0][key]
        m.redraw()
      })
  },
  onremove () {
  },
  oninit (vnode) {
    this.value = '...'
    this._loadValue(vnode.attrs.model, vnode.attrs.query, vnode.attrs.key)
  },
  view (vnode) {
    return m('span', {}, this.value)
  }
}
