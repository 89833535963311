const ds = require('./ds')

module.exports = {
  cacheFlush () {
    return ds.query('mutation { cacheFlush }')
  },
  eval (opts) {
    return ds.query('mutation ($query: String!) { eval(query: $query) }', opts)
  },
  objectCreate (opts) {
    return ds.query('mutation ($model: String!, $id: String!, $data: JSON!) { objectCreate(model: $model, id: $id, data: $data) }', opts)
  },
  objectUpdate (opts) {
    return ds.query('mutation ($model: String!, $id: String!, $data: JSON!) { objectUpdate(model: $model, id: $id, data: $data) }', opts)
  },
  objectUpdateSimple (opts) {
    return ds.query('mutation ($model: String!, $id: String!, $data: JSON!) { objectUpdateSimple(model: $model, id: $id, data: $data) }', opts)
  },
  objectUpdateWithMultipleKeys (opts) {
    return ds.query('mutation ($model: String!, $id: String!, $data: JSON!, $keys: [String]!) { objectUpdateWithMultipleKeys(model: $model, id: $id, data: $data, keys: $keys) }', opts)
  },
  objectDelete (opts) {
    return ds.query('mutation ($model: String!, $id: String!) { objectDelete(model: $model, id: $id) }', opts)
  },
  userSession (opts) {
    return ds.query('mutation ($id: String!) { userSession(id: $id) { token } }', opts)
  },
  mfaUsage (opts) {
    return ds.query('mutation ($organizationId: String!, $month: String!) { mfaUsage(organizationId: $organizationId, month: $month) { totp, sms, email } }', opts)
  },
  toggleDsPro (opts) {
    return ds.query('mutation ($id: String!, $value: Boolean!, $ownerEmail: String) { toggleDsPro(id: $id, value: $value, ownerEmail: $ownerEmail) }', opts)
  },
  workspaceMigrate (opts) {
    return ds.query('mutation ($id: String!, $env: String!, $force: Boolean, $ktp: JSON, $localTarget: String) { workspaceMigrate(id: $id, env: $env, force: $force, ktp: $ktp, localTarget: $localTarget) }', opts)
  },
  organizationMigrate (opts) {
    return ds.query('mutation ($id: String!, $env: String!, $force: Boolean, $ktp: JSON, $localTarget: String) { organizationMigrate(id: $id, env: $env, force: $force, ktp: $ktp, localTarget: $localTarget) }', opts)
  },
  snippetRun (opts) {
    return ds.query('mutation ($source: String!, $input: JSON) { snippetRun(source: $source, input: $input) { error output }}', opts)
  }
}
