const m = require('mithril')
const ds = require('../lib/ds')
const store = require('../lib/store')

const loginData = {}
let error = null

function doLogin (vnode) {
  return function () {
    ds.query(`
  mutation ($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
  `, loginData)
      .then((out) => {
        console.log(`result: ${JSON.stringify(out, null, 2)}`)
        if (out.data.login) {
          const token = out.data.login.token
          vnode.attrs.logged = true
          console.log(`logged in!: ${token}`)
          store.set(vnode, { auth: { token, username: loginData.username } })
          m.route.set('/status')
          m.redraw()
        } else if (out.errors) {
          error = out.errors[0].message
        }
      })
    return false
  }
}

module.exports = {

  oninit () {
    if (store.get('auth')) {
      m.route.set('/status')
    }
  },

  view (vnode) {
    return m('.columns.is-centered', [
      m('.column.is-one-third.has-text-centered', [
        m('.title.is-3.is-centered', m('img', { width: 100, src: 'https://app.dayswaps.com/image/dayswaps-logo.png' })),
        m('.title.is-3.is-centered', [
          m('span', { style: `display: inline-block; width: 25px; height: 25px; background-color: ${vnode.attrs.env.color}; margin-right: 10px;` }),
          m('span', { style: `color: ${vnode.attrs.env.color}` }, vnode.attrs.env.name),
          m('.tag', { style: 'margin-left: 1em;' }, vnode.attrs.apiMeta.version)
        ]),
        m('form', { onsubmit: doLogin(vnode) }, [
          error ? m('.notification.is-danger', error) : null,
          m('.field', [
            m('p.control.has-icons-left', [
              m('input.input', { placeholder: 'Username', onchange: (e) => { loginData.username = e.currentTarget.value } }),
              m('span.icon.is-small.is-left', m('i.fas.fa-envelope'))
            ])
          ]),
          m('.field', [
            m('p.control.has-icons-left', [
              m('input.input', { type: 'password', placeholder: 'Password', onchange: (e) => { loginData.password = e.currentTarget.value } }),
              m('span.icon.is-small.is-left', m('i.fas.fa-lock'))
            ])
          ]),
          m('.field', [
            m('.control.has-text-centered', [
              m('button.button.is-success', 'Login')
            ])
          ])
        ])
      ])
    ])
  }

}
