const m = require('mithril')
const yaml = require('js-yaml')
const dateFns = require('date-fns')
const data = require('../lib/data')
const master = require('../lib/master')
const Colored = require('./colored')

const pkg = require('../../package.json')

const instanceTypes = [
  { id: 'ds-server', name: 'api' },
  { id: 'ds-fileserver', name: 'fileserver' },
  { id: 'ds-planner', name: 'planner', runtime: 'python' }
]

const runtimeIcons = {
  node: 'fab fa-node-js',
  python: 'fab fa-python'
}

let pageStore = {}

function utable (arr, cols = [{ key: 'key', head: true }, { key: 'value' }]) {
  return m('table.table', [
    m('tbody', arr.map(i => {
      return m('tr', cols.map(c => {
        return m(c.head ? 'th' : 'td', { style: c.head ? 'text-align: right; width: 15%;' : '' }, i[c.key])
      }))
    }))
  ])
}

function uptimeRenderer (time) {
  return [dateFns.formatDistanceToNow(dateFns.subSeconds(new Date(), time)), m('small', ` (${time}s)`)]
}

function driverView (d) {
  return [m('a', { href: d.url }, d.name), d.version ? ` (v${d.version})` : '']
}

function censor (val) {
  const re = /:\/\/([^:]+):([^@]+)@/
  if (val.match(re)) {
    return val.replace(re, '://$1:{pwd}@')
  }
  return val
}

function colorBoolean (val) {
  if (val === null) {
    return m('span', { style: 'color: silver' }, '(null)')
  }
  const color = val ? 'green' : 'red'
  return m('span', { style: `color: ${color};` }, val ? 'Yes' : 'No')
}

function loadMaster (vnode) {
  return master.instances({ env: vnode.attrs.env.tag }).then(res => {
    pageStore.instances = res
    m.redraw()
  })
}

function loadPageStore (vnode) {
  return Promise.all([
    data.cacheInfo().then(res => {
      pageStore.cacheInfo = res.data.cacheInfo
      m.redraw()
    }),
    data.socketInfo().then(res => {
      pageStore.socketInfo = res.data.socketInfo
      m.redraw()
    }),
    data.meta().then(res => {
      pageStore.meta = res.data.meta
      m.redraw()
    })
  ])
    .then(() => {
      m.redraw()
    })
}

function refresh (vnode) {
  return () => {
    pageStore = {}
    loadPageStore(vnode)
    loadMaster(vnode)
  }
}

function metric (i, name, unit = null) {
  const data = i.data
  if (!data.pm2) {
    return '-'
  }
  const val = data.pm2.pm2_env.axm_monitor[name]
  if (!val) {
    return '-'
  }
  return m('small', [(Math.round(val.value * 100) / 100), unit || val.unit])
}

let periodic = null
let periodicMaster = null

module.exports = {

  oninit (vnode) {
    pageStore = {}
    loadPageStore(vnode)
    loadMaster(vnode)
    periodic = setInterval(() => loadPageStore(vnode), 30000)
    periodicMaster = setInterval(() => loadMaster(vnode), 5000)
  },

  onremove () {
    if (periodic) {
      clearInterval(periodic)
    }
    if (periodicMaster) {
      clearInterval(periodicMaster)
    }
  },

  view (vnode) {
    const conf = vnode.attrs.config
    return m('div', [
      m('.level', [
        m('.level-left', [
          m('.title.is-3.level-item', [m('i', { class: 'far fa-check-circle' }), `Status [${vnode.attrs.env.name}]`])
        ]),
        m('.level-right', [
          m('.level-item', [
            m('button.button', { onclick: refresh(vnode) }, 'Refresh')
          ])
        ])
      ]),
      m('.title.is-4', 'Instances'),
      m('div', instanceTypes.map(it => {
        const rt = it.runtime || 'node'
        return m('div.card', { style: 'margin-bottom: 2em;' }, [
          m('.card-header', [
            m('.level.card-header-title', [
              m('.level-left', [
                m('.level-item', m('.title.is-5', it.name)),
                m('.level-item', { style: 'font-weight: normal;' }, [m('i', { class: runtimeIcons[rt], style: 'font-size: 20px;margin-right: 7px;' }), rt])
              ]),
              m('.level-right', [
              ])
            ])
          ]),
          m('.card-content', { style: 'padding: 0;' }, [
            m('table.table.is-fullwidth', [
              m('thead', [
                m('tr', [
                  m('th', ''),
                  m('th', ''),
                  m('th', 'v'),
                  m('th', 'CPU'),
                  m('th', 'Mem'),
                  // m('th', m('span', { title: 'Heap Usage (v8)' }, 'HU')),
                  m('th', m('span', { title: 'Event Loop Latency p50/p95' }, 'ELL')),
                  m('th', 'HTTP'),
                  m('th', m('span', { title: 'HTTP Mean Latency p50/p95' }, 'HTL')),
                  m('th', 'Platform'),
                  m('th', m('span', { title: 'Last announce' }, 'LA')),
                  m('th', 'Uptime')
                ])
              ]),
              m('tbody', (() => {
                if (!pageStore.instances) {
                  return m('tr', { colspan: 50 }, 'Loading ..')
                }
                const arr = pageStore.instances.filter(i => i.type === it.id).sort((x, y) => x.host > y.host ? 1 : -1)
                if (arr.length === 0) {
                  return m('tr', [
                    m('td', { style: 'background-color: hsl(348, 100%, 61%); width: 32px;' }),
                    m('td', { colspan: 1 }, 'No instances running!')
                  ])
                }
                return arr.map(i => {
                  const [host] = i.id.split(':')
                  const la = dateFns.differenceInSeconds(new Date(), new Date(i.time))
                  const activeColor = la < 7 ? 'hsl(141, 71%, 48%)' : 'hsl(48, 100%, 67%)'

                  return m('tr', { key: i.id }, [
                    m('td', { style: `background-color: ${activeColor}; width: 32px;` }),
                    m('td', m('a', { href: `https://papertrailapp.com/systems/${host}/events`, target: '_blank' }, i.id)),
                    m('td', i.data.stat.versions.app),
                    m('td', i.data.pm2 ? m('small', i.data.pm2.monit.cpu + '%') : '-'),
                    m('td', i.data.pm2 ? m('small', (Math.round((i.data.pm2.monit.memory / 1024 / 1024) * 100) / 100) + ' MB') : '-'),
                    // m('td', i.data.pm2 ? m('small', ( i.data.pm2.pm2_env.axm_monitor['Heap Usage'].value + '% ')) : '-'),
                    m('td', [metric(i, 'Event Loop Latency'), '/', metric(i, 'Event Loop Latency p95')]),
                    m('td', metric(i, 'HTTP', 'rpm')),
                    m('td', [metric(i, 'HTTP Mean Latency'), '/', metric(i, 'HTTP P95 Latency')]),
                    m('td', m('small', i.data.stat.versions.node + ` (${i.data.stat.platform})`)),
                    m('td', m('small', la < 5 ? '<5s' : `${la}s`)),
                    m('td', i.data.pm2 ? m('small', dateFns.formatDistanceToNow(new Date(i.data.pm2.pm2_env.created_at))) : '-')
                  ])
                })
              })())
            ])
          ])
        ])
      })),
      m('.title.is-4', 'Backend'),
      m('.content', utable([
        { key: 'version', value: [m('b', vnode.attrs.apiMeta.version), ' (', m('a', { href: `https://github.com/Dayswaps/backend/tree/v${vnode.attrs.apiMeta.version}` }, 'GitHub tag'), ') '] },
        { key: 'commit', value: [m('a', { href: `https://github.com/Dayswaps/backend/commit/${vnode.attrs.apiMeta.commit}` }, vnode.attrs.apiMeta.commit)] }
      ])),
      m('.title.is-4', 'Database [mongo]'),
      m('.content', utable([
        { key: 'mongo url', value: censor(conf.core.db.mongo.connect) },
        { key: 'options', value: m(Colored, { text: yaml.safeDump(conf.core.db.mongo.options) }) }
      ])),
      m('.title.is-4', ['Socket', pageStore.socketInfo ? ` [${pageStore.socketInfo.type}]` : '']),
      pageStore.socketInfo
        ? m('.content', utable([
          { key: 'driver', value: driverView(pageStore.socketInfo.driver) },
          { key: 'nats servers', value: censor(pageStore.socketInfo.servers) },
          { key: 'group', value: m('b', pageStore.socketInfo.group) },
          { key: 'subscriptions', value: pageStore.socketInfo.numSubscriptions }
        ]))
        : m('div', 'Loading NATS status ..'),
      m('.title.is-4', ['Cache', ' [redis]']),
      pageStore.cacheInfo
        ? m('.content', utable([
          { key: 'enabled', value: colorBoolean(conf.core.redis.enabled) },
          { key: 'driver', value: driverView(pageStore.cacheInfo.driver) },
          { key: 'redis url', value: conf.core.redis.url },
          { key: 'server version', value: pageStore.cacheInfo.serverInfo.redis_version },
          { key: 'server uptime', value: uptimeRenderer(pageStore.cacheInfo.serverInfo.uptime_in_seconds) },
          { key: 'server clients', value: pageStore.cacheInfo.serverInfo.connected_clients },
          { key: 'keys', value: pageStore.cacheInfo.items }
        ]))
        : m('div', 'Loading cache status ..'),
      m('.title.is-4', 'Runtime'),
      m('.content', utable([
        { key: 'server uptime', value: pageStore.meta ? uptimeRenderer(pageStore.meta.uptime) : 'n/a' },
        { key: 'node', value: m('b', vnode.attrs.apiMeta.node) },
        { key: 'platform', value: vnode.attrs.apiMeta.platform },
        { key: 'arch', value: vnode.attrs.apiMeta.arch }
      ])),
      m('.title.is-4', 'Public API'),
      m('.content', utable([
        { key: 'graphql url', value: m('a', { href: vnode.attrs.env.apiPublic }, vnode.attrs.env.apiPublic) },
        { key: 'tracing', value: colorBoolean(conf.core.server.tracing) },
        { key: 'introspection', value: colorBoolean(conf.core.server.introspection) },
        { key: 'playground', value: colorBoolean(conf.core.server.playground) }
      ])),
      m('.title.is-4', 'Admin API'),
      m('.content', utable([
        { key: 'graphql url', value: m('a', { href: vnode.attrs.env.api }, vnode.attrs.env.api) },
        { key: 'tracing', value: colorBoolean(conf.core.admin_server.tracing) },
        { key: 'introspection', value: colorBoolean(conf.core.admin_server.introspection) },
        { key: 'playground', value: colorBoolean(conf.core.admin_server.playground) }
      ])),
      m('.title.is-4', 'Fileserver'),
      m('.content', utable([
        { key: 'url', value: m('a', { href: conf.core.fileserver.url }, conf.core.fileserver.url) },
        { key: 'short url', value: m('a', { href: conf.core.fileserver.short_url }, conf.core.fileserver.short_url) }
      ])),
      m('.title.is-4', 'Frontend'),
      m('.content', utable([
        { key: 'url', value: m('a', { href: conf.core.frontend.url }, conf.core.frontend.url) }
      ])),
      m('.title.is-4', 'Admin UI'),
      m('.content', utable([
        { key: 'host', value: vnode.attrs.env.host.toString() },
        { key: 'version', value: m('b', pkg.version) }
      ])),
      m('div', { style: 'margin-bottom: 5em;' })
    ])
  }
}
