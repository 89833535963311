/* global localStorage */

class Store {
  constructor () {
    this.data = {}
  }

  init (vnode, data) {
    this.data = Object.assign(this.data, data)
    vnode = this.data
  }

  set (vnode, data) {
    this.data = data === null ? { auth: null } : Object.assign(this.data, data)
    localStorage.setItem('store', JSON.stringify(this.data))
    Object.assign(vnode, this.data)
  }

  get (prop = null) {
    if (prop) {
      return this.data[prop]
    }
    return this.data
  }
}

class StoreWrapper {
  constructor () {
    if (!StoreWrapper.instance) {
      StoreWrapper.instance = new Store()
    }
  }

  getInstance () {
    return StoreWrapper.instance
  }
}

module.exports = (() => (new StoreWrapper()).getInstance())()
